<template>
  <div>
    <b-table-simple class="table-first-column-bold">
      <tr>
        <td>Google Maps Define</td>
        <td>
          {{dataset.google_maps_define}}
        </td>
      </tr>
      <tr>
        <td>Google Maps ACF</td>
        <td>
          {{dataset.google_maps_acf}}
        </td>
      </tr>
      <tr>
        <td>Heeft Gravity Forms formulieren met legacy styling</td>
        <td>
          <template v-if="dataset.gf_legacy === 1">
            <i class="far fa-circle-check text-success" />
          </template>

          <template v-else>
            <i class="far fa-circle-xmark text-danger" />
          </template>
        </td>
      </tr>
      <tr v-if="dataset.gf_legacy === 1">
        <td>Aantal Gravity Forms formulieren met legacy styling</td>
        <td>
          {{dataset.gf_legacy_count}}
        </td>
      </tr>
    </b-table-simple>
  </div>
</template>

<script>
  export default {
    props: {
      dataset: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>