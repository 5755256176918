<template>
  <b-container fluid>
    <h1>{{ $route.meta.title }}</h1>

    <b-row>
      <b-col md="10">
        <b-card class="filters-sidebar">
          <b-form-group
            label="Zoeken"
            description="Druk op `Enter` om te zoeken"
          >
            <b-input
              v-model="filters.terms"
              placeholder="Site"
              @keyup.enter="filter()"
            />
          </b-form-group>

          <b-form-group label="Publiek">
            <b-form-checkbox-group
              v-model="filters.public"
              name="public_filter"
              stacked
              @change="filter()"
            >
              <b-form-checkbox :unchecked-value="null" :value="true">
                Ja
              </b-form-checkbox>
              <b-form-checkbox :unchecked-value="null" :value="false">
                Nee
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group label="SSL">
            <b-form-checkbox-group
              v-model="filters.ssl"
              name="ssl_filter"
              stacked
              @change="filter()"
            >
              <b-form-checkbox :unchecked-value="null" :value="true">
                Ja
              </b-form-checkbox>
              <b-form-checkbox :unchecked-value="null" :value="false">
                Nee
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group label="WP Versie">
            <b-form-checkbox-group
              v-model="filters.wpVersions"
              name="wp_filter"
              stacked
              @change="filter()"
            >
              <template v-if="showLess.wpVersions">
                <b-form-checkbox
                  v-for="version in filterValues.wp_versions.slice(0, 4)"
                  :key="version"
                  :value="version"
                >
                  {{ version }}
                </b-form-checkbox>

                <b-link
                  class="checkbox-show-more-less"
                  @click="showLess.wpVersions = false"
                >
                  Laat alles zien
                </b-link>
              </template>
              <template v-else>
                <b-form-checkbox
                  v-for="version in filterValues.wp_versions"
                  :key="version"
                  :value="version"
                >
                  {{ version }}
                </b-form-checkbox>

                <b-link
                  class="checkbox-show-more-less"
                  @click="showLess.wpVersions = true"
                >
                  Laat minder zien
                </b-link>
              </template>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group label="PHP Versie">
            <b-form-checkbox-group
              v-model="filters.phpVersions"
              name="php_filter"
              stacked
              @change="filter()"
            >
              <template v-if="showLess.phpVersions">
                <b-form-checkbox
                  v-for="version in filterValues.php_versions.slice(0, 4)"
                  :key="version"
                  :value="version"
                >
                  {{ version }}
                </b-form-checkbox>

                <b-link
                  class="checkbox-show-more-less"
                  @click="showLess.phpVersions = false"
                >
                  Laat alles zien
                </b-link>
              </template>
              <template v-else>
                <b-form-checkbox
                  v-for="version in filterValues.php_versions"
                  :key="version"
                  :value="version"
                >
                  {{ version }}
                </b-form-checkbox>

                <b-link
                  class="checkbox-show-more-less"
                  @click="showLess.phpVersions = true"
                >
                  Laat minder zien
                </b-link>
              </template>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group label="Server">
            <b-form-checkbox-group
              v-model="filters.servers"
              name="server_filter"
              stacked
              @change="filter()"
            >
              <template v-if="showLess.servers">
                <b-form-checkbox
                  v-for="server in filterValues.servers.slice(0, 4)"
                  :key="server"
                  :value="server"
                >
                  {{ server }}
                </b-form-checkbox>

                <b-link
                  class="checkbox-show-more-less"
                  @click="showLess.servers = false"
                >
                  Laat alles zien
                </b-link>
              </template>
              <template v-else>
                <b-form-checkbox
                  v-for="server in filterValues.servers"
                  :key="server"
                  :value="server"
                >
                  {{ server }}
                </b-form-checkbox>

                <b-link
                  class="checkbox-show-more-less"
                  @click="showLess.servers = true"
                >
                  Laat minder zien
                </b-link>
              </template>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group label="Heeft GF legacy formulieren"  class="mb-0">
            <b-form-checkbox-group
                v-model="filters.gfLegacy"
                name="gf_legacy_filter"
                stacked
                @change="filter()"
            >
              <b-form-checkbox :unchecked-value="null" :value="true">
                Ja
              </b-form-checkbox>
              <b-form-checkbox :unchecked-value="null" :value="false">
                Nee
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-card>
      </b-col>

      <b-col md="38">
        <b-card class="card-table" no-body>
          <b-table
            :items="sites"
            :fields="fields"
            responsive
            show-empty
            :busy="loading.sites"
          >
            <template #table-busy>
              <b-spinner variant="primary" class="align-middle mr-3" />
              <strong class="align-middle">Sites worden geladen</strong>
            </template>

            <template #empty=""> Er zijn geen sites gevonden </template>

            <template #head(core_auto_update)="item">
              {{ item.label }}
              <span
                class="ml-1"
                v-b-tooltip.hover.bottom
                title='ACU staat voor "Automatic Core Update". In het geval van een groen vinkje staat dit uitgeschakeld. Wanneer dit is ingeschakeld zal een rood kruisje getoond worden.'
              >
                <i class="far fa-info-circle" />
              </span>
            </template>

            <template #head(auto_update_disabled)="item">
              {{ item.label }}
              <span
                class="ml-1"
                v-b-tooltip.hover.bottom
                title='AU staat voor "Auto Update Disabled". In het geval van een groen vinkje worden updates niet automatisch geïnstalleerd. Wanneer dit wel gebeurd zal een rood kruisje getoond worden.'
              >
                <i class="far fa-info-circle" />
              </span>
            </template>

            <template #cell(name)="row">
              <div>
                <Favicon :href="row.item.url" />
                {{ row.item.name }}
              </div>
              <div class="column-actions">
                <router-link
                  v-b-tooltip.hover.bottom="`Informatie bekijken`"
                  :to="{
                    name: 'sites.show',
                    params: {
                      id: row.item.id,
                    },
                  }"
                  class="column-action"
                >
                  <i class="far fa-arrow-right" />
                </router-link>
                <a
                  v-b-tooltip.hover.bottom="`Site openen`"
                  :href="row.item.metaData.url"
                  class="column-action"
                  target="_blank"
                >
                  <i class="far fa-arrow-up-right-from-square" />
                </a>
                <a
                  v-b-tooltip.hover.bottom="`Backend openen`"
                  :href="row.item.metaData.login_url"
                  class="column-action"
                  target="_blank"
                >
                  <i class="far fa-unlock" />
                </a>
              </div>
            </template>

            <template #cell(admin_email)="row">
              {{ row.item.metaData.admin_email }}
            </template>

            <template #cell(public)="row">
              <template v-if="row.item.metaData.public">
                <i class="far fa-circle-check text-success" />
              </template>

              <template v-else>
                <i class="far fa-circle-xmark text-danger" />
              </template>
            </template>

            <template #cell(wp_version)="row">
              {{ row.item.metaData.version }}
            </template>

            <template #cell(server)="row">
              {{ row.item.server.host }}
            </template>

            <template #cell(php)="row">
              {{ row.item.server.php }}
            </template>

            <template #cell(php_memory)="row">
              {{ row.item.server.php_memory }} MB
            </template>

            <template #cell(auto_update_core)="row">
              <template v-if="row.item.metaData.auto_update_core">
                <i class="far fa-circle-check text-success" />
              </template>
              <template v-else>
                <i class="far fa-circle-xmark text-danger" />
              </template>
            </template>

            <template #cell(auto_update_disabled)="row">
              <template v-if="row.item.metaData.auto_update_disabled">
                <i class="far fa-circle-check text-success" />
              </template>
              <template v-else>
                <i class="far fa-circle-xmark text-danger" />
              </template>
            </template>

            <template #cell(admins)="row">
              {{ totalAdminUsers(row.item.users) }}
            </template>

            <template #cell(gf_legacy_count)="row">
              {{ row.item.other.gf_legacy_count }}
            </template>

            <template #cell(ssl)="row">
              <template v-if="row.item.metaData.has_ssl">
                <i class="far fa-circle-check text-success" />
              </template>

              <template v-else>
                <i class="far fa-circle-xmark text-danger" />
              </template>
            </template>
          </b-table>
        </b-card>

        <Pagination
          v-if="sitesMeta.last_page > 1"
          :current-page="sitesMeta.current_page"
          :number-of-pages="sitesMeta.last_page"
          @change="index"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        sites: [],
        sitesMeta: {},

        fields: [
          { key: "name", label: "Naam" },
          { key: "admin_email", label: "Admin e-mail" },
          { key: "public", label: "Publiek", tdClass: "text-center" },
          { key: "ssl", label: "SSL", tdClass: "text-center" },
          { key: "wp_version", label: "WP Versie" },
          { key: "server", label: "Server" },
          { key: "php", label: "PHP Versie" },
          {
            key: "auto_update_core",
            label: "ACU",
            tdClass: "text-center",
          },
          {
            key: "auto_update_disabled",
            label: "AU",
            tdClass: "text-center",
          },
          { key: "plugins_count", label: "Plugins", tdClass: "text-center" },
          { key: "admins", label: "Beheerders", tdClass: "text-center" },
          { key: "gf_legacy_count", label: "GF Legacy", tdClass: "text-center" },
        ],

        showLess: {
          wpVersions: true,
          phpVersions: true,
          servers: true,
        },

        filterValues: {
          wp_versions: [],
          php_versions: [],
          servers: [],
        },

        filters: {
          wpVersions: [],
          phpVersions: [],
          servers: [],
          terms: null,
          ssl: null,
          public: null,
          gfLegacy: null,
          orderBy: null,
          orderDirection: null,
        },

        loading: {
          sites: false,
        },
      };
    },

    methods: {
      filter() {
        this.$router.push({ name: "sites.index", params: { page: null } });

        this.index();
      },

      getFilters() {
        this.$http.get("sites/filters").then(({ data }) => {
          this.filterValues = data.data;
        });
      },

      index() {
        this.loading.sites = true;

        let params = {
          ...this.filters,
          page: this.$route.query.pagina ? this.$route.query.pagina : null,
        };

        this.$http
          .get("sites", { params: params })
          .then(({ data }) => {
            this.sites = data.data;
            this.sitesMeta = data.meta;
          })
          .finally(() => {
            this.loading.sites = false;
          });
      },

      totalAdminUsers(users) {
        const adminObject = users.filter((object) => {
          return object.role === "administrator";
        });

        return adminObject[0] ? adminObject[0].count : 0;
      },
    },

    mounted() {
      this.getFilters();

      this.index();
    },
  };
</script>
